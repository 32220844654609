<template>

  <b-row>

    <!-- Left -->
    <b-col
      cols="12"
      lg="9"
    >

      <b-alert
        :show="userData.role === 'admin' && formUpdateStatus === 1"
        variant="warning"
        style="padding: 0.71rem 1rem; border: 1px solid transparent;"
      >
        Please see the following list of requested changes.
        <ul
          class="d-flex flex-column flex-wrap mt-50"
          style="max-height: 300px;"
        >
          <li
            v-for="(value, index) in formUpdateChanges"
            :key="index"
          >
            {{ value }}
          </li>
        </ul>
        <hr>
        <div class="text-right">
          <b-button
            variant="danger"
            class="mr-1"
            @click="denyFormUpdate"
          >
            Deny
          </b-button>
          <b-button
            variant="secondary"
            @click="approveFormUpdate"
          >
            Approve
          </b-button>
        </div>
      </b-alert>

      <b-alert
        :show="userData.role === 'agent' && formUpdateStatus === 1"
        variant="warning"
        style="padding: 0.71rem 1rem; border: 1px solid transparent;"
      >
        You still have pending profile update requests. Please wait for the Admin to review them before making any other changes.
      </b-alert>

      <b-alert
        :show="userData.role === 'agent' && formUpdateStatus === 2"
        variant="primary"
        dismissible
        fade
        style="padding: 0.71rem 1rem; cursor: pointer; border: 1px solid transparent;"
        @dismissed="dismissFormUpdate"
      >
        The HR/Admin has approved the changes you made.
      </b-alert>

      <b-alert
        :show="userData.role === 'agent' && formUpdateStatus === 3"
        variant="danger"
        dismissible
        fade
        style="border: 1px solid transparent;"
        @dismissed="dismissFormUpdate"
      >
        <div
          style="padding: 0.71rem 1rem; cursor: pointer;"
          @click="dismissFormUpdate"
        >
          The HR/Admin has denied the changes you made.
        </div>
      </b-alert>

      <b-overlay
        :show="userData.role === 'agent' && formUpdateStatus > 0"
        spinner-type="false"
        opacity="0.25"
        blur
        rounded
      >

        <!-- Card -->
        <b-card>

          <b-row class="hr-form">

            <b-col
              cols="12"
            >

              <b-card-title>
                Request for Live Scan Service - Community Care Licensing
                <br>
                <small>Applicant Submission</small>
              </b-card-title>

              <!-- Form -->
              <validation-observer ref="simpleRules">
                <b-form>

                  <b-form-input
                    v-model="form.form"
                    type="text"
                    class="hidden"
                  />

                  <b-form-input
                    v-model="form.id"
                    type="text"
                    class="hidden"
                  />

                  <h4>Section 1: Originating Response Indicator (ORI)</h4>

                  <!-- Input ORI -->
                  <b-row>

                    <b-col
                      cols="12"
                      lg="4"
                    >

                      <b-form-group
                        label="* ORI"
                        label-for="ori"
                      >
                        <label
                          class="sr-only"
                          for="ori"
                        >
                          * ORI
                        </label>
                        <validation-provider
                          #default="{ errors }"
                          name="ORI"
                          vid="ori"
                          rules="required"
                        >
                          <b-form-input
                            id="ori"
                            ref="ori"
                            v-model="form.ori"
                            :state="errors.length > 0 ? false : null"
                            type="text"
                            placeholder="ORI"
                            readonly
                            title="ORI"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>

                    </b-col>
                  </b-row>

                  <!-- Spacer -->
                  <hr>

                  <h4>Section 2: Working Title</h4>

                  <!-- Radio Working Title -->
                  <b-row>

                    <b-col
                      cols="12"
                    >

                      <b-form-group
                        label="* Working Title: (Choose one)"
                        label-for="working-title"
                      >
                        <label
                          class="sr-only"
                          for="working-title"
                        >
                          * Working Title: (Choose one)
                        </label>
                        <validation-provider
                          #default="{ errors }"
                          name="Working Title"
                          rules="required"
                        >
                          <b-form-radio
                            ref="workingTitle"
                            v-model="form.workingTitle"
                            :state="errors.length > 0 ? false : null"
                            value="Adult Resident other than Client"
                            title="Working Title"
                          >
                            Adult Resident other than Client
                          </b-form-radio>
                          <b-form-radio
                            ref="workingTitle"
                            v-model="form.workingTitle"
                            :state="errors.length > 0 ? false : null"
                            value="Employee"
                            title="Working Title"
                          >
                            Employee
                          </b-form-radio>
                          <b-form-radio
                            ref="workingTitle"
                            v-model="form.workingTitle"
                            :state="errors.length > 0 ? false : null"
                            value="License, Certification, Applicant"
                            title="Working Title"
                          >
                            License, Certification, Applicant
                          </b-form-radio>
                          <b-form-radio
                            ref="workingTitle"
                            v-model="form.workingTitle"
                            :state="errors.length > 0 ? false : null"
                            value="Volunteer"
                            title="Working Title"
                          >
                            Volunteer
                          </b-form-radio>
                          <b-form-radio
                            ref="workingTitle"
                            v-model="form.workingTitle"
                            :state="errors.length > 0 ? false : null"
                            value="Home Care Aide Registry Applicant"
                            title="Working Title"
                          >
                            Home Care Aide Registry Applicant
                          </b-form-radio>
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>

                    </b-col>
                  </b-row>

                  <!-- Spacer -->
                  <hr>

                  <h4>Section 3: Authorized Applicant Type</h4>

                  <!-- Input Authorized Applicant Type -->
                  <b-row>

                    <b-col
                      cols="12"
                      lg="8"
                    >

                      <validation-provider
                        #default="{ errors }"
                        name="Authorized Applicant Type"
                        rules="required"
                      >
                        <b-form-group
                          label="* Authorized Applicant Type: DOJ Abbreviated CCLD Facility/Organization Type"
                          label-for="authorized-applicant-type"
                          :state="errors.length > 0 ? false : null"
                        >
                          <label
                            class="sr-only"
                            for="authorized-applicant-type"
                          >
                            * Authorized Applicant Type: DOJ Abbreviated CCLD Facility/Organization Type
                          </label>
                          <v-select
                            ref="authorizedApplicantType"
                            v-model="form.authorizedApplicantType"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="authorizedApplicantTypeOptions"
                            placeholder="Select Authorized Applicant Type"
                            title="Authorized Applicant Type"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </b-form-group>
                      </validation-provider>

                    </b-col>
                  </b-row>

                  <p>Select your licensed facility type from the left column, and in the right column find its corresponding DOJ abbreviated facility type. <b>Enter the corresponding DOJ abbreviated facility type on this line.</b></p>
                  <p><b>Note:</b> In the following table you may be able to identify yourself with more than one facility type within each category. Please select only one facility type in any category using the facility that you are most associated with on a day-to-day basis.</p>

                  <!-- Table -->
                  <b-table
                    thead-class="d-none"
                    :fields="fields"
                    :items="items"
                    responsive
                  >
                    <!-- Column first -->
                    <template #cell(first)="data">
                      <span class="ws-pre-line">
                        <b>{{ data.value }}</b>
                      </span>
                    </template>

                    <!-- Column last -->
                    <template #cell(last)="data">
                      <span class="ws-pre-line">
                        <b>{{ data.value }}</b>
                      </span>
                    </template>
                  </b-table>

                  <!-- Table -->
                  <b-table
                    :fields="fields1"
                    :items="items1"
                    responsive
                  >
                    <!-- Column left -->
                    <template #cell(left)="data">
                      <span class="ws-pre-line">
                        {{ data.value }}
                      </span>
                    </template>

                    <!-- Column right -->
                    <template #cell(right)="data">
                      <span class="text-nowrap">
                        {{ data.value }}
                      </span>
                    </template>
                  </b-table>

                  <!-- Spacer -->
                  <hr>

                  <h4>Section 4: Agency Address Set Contributing Agency</h4>

                  <b-row>

                    <!-- Input Agency -->
                    <b-col
                      cols="12"
                      lg="8"
                    >

                      <b-form-group
                        label="* Agency authorized to receive criminal history information"
                        label-for="agency"
                      >
                        <label
                          class="sr-only"
                          for="agency"
                        >
                          * Agency authorized to receive criminal history information
                        </label>
                        <validation-provider
                          #default="{ errors }"
                          name="Agency authorized to receive criminal history information"
                          vid="agency"
                          rules="required"
                        >
                          <b-form-input
                            id="agency"
                            ref="agency"
                            v-model="form.agency"
                            :state="errors.length > 0 ? false : null"
                            type="text"
                            placeholder="Agency authorized to receive criminal history information"
                            readonly
                            title="Agency authorized to receive criminal history information"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>

                    </b-col>

                    <!-- Input Agency Mail Code -->
                    <b-col
                      cols="12"
                      lg="4"
                    >

                      <b-form-group
                        label="* Mail Code (5-digit code assigned by DOJ.)"
                        label-for="agency-mail-code"
                      >
                        <label
                          class="sr-only"
                          for="agency-mail-code"
                        >
                          * Mail Code
                        </label>
                        <validation-provider
                          #default="{ errors }"
                          name="Mail Code (5-digit code assigned by DOJ.)"
                          vid="agency-mail-code"
                          rules="required"
                        >
                          <b-form-input
                            id="agency-mail-code"
                            ref="agencyMailCode"
                            v-model="form.agencyMailCode"
                            :state="errors.length > 0 ? false : null"
                            type="text"
                            placeholder="Mail Code"
                            readonly
                            title="Agency Mail Code"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>

                    </b-col>
                  </b-row>

                  <!-- Spacer -->
                  <br>

                  <b-row>

                    <!-- Input Agency Street Number and/or Name -->
                    <b-col
                      cols="12"
                      lg="8"
                    >

                      <b-form-group
                        label="* Street Number and/or Name"
                        label-for="agency-street"
                      >
                        <label
                          class="sr-only"
                          for="agency-street"
                        >
                          * Street Number and/or Name
                        </label>
                        <validation-provider
                          #default="{ errors }"
                          name="Street Number and/or Name"
                          vid="agency-street"
                          rules="required"
                        >
                          <b-form-input
                            id="agency-street"
                            ref="agencyStreet"
                            v-model="form.agencyStreet"
                            :state="errors.length > 0 ? false : null"
                            type="text"
                            placeholder="Street Number and/or Name"
                            readonly
                            title="Agency Street Number and/or Name"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>

                    </b-col>

                    <!-- Input Agency Street or POB -->
                    <b-col
                      cols="12"
                      lg="4"
                    >

                      <b-form-group
                        label="Street or POB"
                        label-for="agency-street-pob"
                      >
                        <label
                          class="sr-only"
                          for="agency-street-pob"
                        >
                          Street or POB
                        </label>
                        <b-form-input
                          id="agency-street-pob"
                          ref="agencyStreetPob"
                          v-model="form.agencyStreetPob"
                          type="text"
                          placeholder="Street or POB"
                          readonly
                          title="Agency Street or POB"
                        />
                      </b-form-group>

                    </b-col>
                  </b-row>

                  <b-row>

                    <!-- Input Agency City or Town -->
                    <b-col
                      cols="12"
                      lg="4"
                    >

                      <b-form-group
                        label="* City or Town"
                        label-for="agency-city-town"
                      >
                        <label
                          class="sr-only"
                          for="agency-city-town"
                        >
                          * City or Town
                        </label>
                        <validation-provider
                          #default="{ errors }"
                          name="City or Town"
                          vid="agency-city-town"
                          rules="required"
                        >
                          <b-form-input
                            id="agency-city-town"
                            ref="agencyCityTown"
                            v-model="form.agencyCityTown"
                            :state="errors.length > 0 ? false : null"
                            type="text"
                            placeholder="City or Town"
                            readonly
                            title="Agency City or Town"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>

                    </b-col>

                    <!-- Input Agency State -->
                    <b-col
                      cols="12"
                      lg="4"
                    >

                      <b-form-group
                        label="* State"
                        label-for="agency-state"
                      >
                        <label
                          class="sr-only"
                          for="agency-state"
                        >
                          * State
                        </label>
                        <validation-provider
                          #default="{ errors }"
                          name="State"
                          vid="agency-state"
                          rules="required"
                        >
                          <b-form-input
                            id="agency-state"
                            ref="agencyState"
                            v-model="form.agencyState"
                            :state="errors.length > 0 ? false : null"
                            type="text"
                            placeholder="State"
                            readonly
                            title="Agency State"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>

                    </b-col>

                    <!-- Input Agency Zip Code -->
                    <b-col
                      cols="12"
                      lg="4"
                    >

                      <b-form-group
                        label="* Zip Code"
                        label-for="agency-zip-code"
                      >
                        <label
                          class="sr-only"
                          for="agency-zip-code"
                        >
                          * Zip Code
                        </label>
                        <validation-provider
                          #default="{ errors }"
                          name="Zip Code"
                          vid="agency-zip-code"
                          rules="required|regex:^[\-0-9]+$"
                        >
                          <b-form-input
                            id="agency-zip-code"
                            ref="agencyZipCode"
                            v-model="form.agencyZipCode"
                            :state="errors.length > 0 ? false : null"
                            type="text"
                            placeholder="Zip Code"
                            readonly
                            title="Agency Zip Code"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>

                    </b-col>
                  </b-row>

                  <!-- Spacer -->
                  <br>

                  <b-row>

                    <!-- Input Agency Contact Name -->
                    <b-col
                      cols="12"
                      lg="8"
                    >

                      <b-form-group
                        label="Contact Name (Mandatory for all school submissions.)"
                        label-for="agency-contact-name"
                      >
                        <label
                          class="sr-only"
                          for="agency-contact-name"
                        >
                          Contact Name (Mandatory for all school submissions.)
                        </label>
                        <b-form-input
                          id="agency-contact-name"
                          ref="agencyContactName"
                          v-model="form.agencyContactName"
                          type="text"
                          placeholder="Contact Name"
                          title="Agency Contact Name"
                        />
                      </b-form-group>

                    </b-col>

                    <!-- Input Agency Contact Number -->
                    <b-col
                      cols="12"
                      lg="4"
                    >

                      <b-form-group
                        label="Contact Number"
                        label-for="agency-contact-number"
                      >
                        <label
                          class="sr-only"
                          for="agency-contact-number"
                        >
                          Contact Number
                        </label>
                        <b-input-group>
                          <b-input-group-prepend is-text>
                            US (+1)
                          </b-input-group-prepend>
                          <b-form-input
                            id="agency-contact-number"
                            ref="agencyContactNumber"
                            v-model="form.agencyContactNumber"
                            v-mask="['(###) ###-####']"
                            type="tel"
                            placeholder="Contact Number"
                            title="Agency Contact Number"
                          />
                        </b-input-group>
                      </b-form-group>

                    </b-col>
                  </b-row>

                  <!-- Spacer -->
                  <hr>

                  <h4>Section 5: Applicant Information</h4>

                  <b-row>

                    <!-- Input Last Name -->
                    <b-col
                      cols="12"
                      lg="4"
                    >

                      <b-form-group
                        label="* Last Name (Family Name)"
                        label-for="last-name"
                      >
                        <label
                          class="sr-only"
                          for="last-name"
                        >
                          * Last Name (Family Name)
                        </label>
                        <validation-provider
                          #default="{ errors }"
                          name="Last Name"
                          vid="last-name"
                          rules="required"
                        >
                          <b-form-input
                            id="last-name"
                            v-model="lastName"
                            :state="errors.length > 0 ? false : null"
                            type="text"
                            placeholder="Last Name"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>

                    </b-col>

                    <!-- Input First Name -->
                    <b-col
                      cols="12"
                      lg="4"
                    >

                      <b-form-group
                        label="* First Name (Given Name)"
                        label-for="first-name"
                      >
                        <label
                          class="sr-only"
                          for="first-name"
                        >
                          * First Name (Given Name)
                        </label>
                        <validation-provider
                          #default="{ errors }"
                          name="First Name"
                          vid="first-name"
                          rules="required"
                        >
                          <b-form-input
                            id="first-name"
                            v-model="firstName"
                            :state="errors.length > 0 ? false : null"
                            type="text"
                            placeholder="First Name"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>

                    </b-col>

                    <!-- Input Middle Name -->
                    <b-col
                      cols="12"
                      lg="4"
                    >

                      <b-form-group
                        label="Middle Name"
                        label-for="middle-name"
                      >
                        <label
                          class="sr-only"
                          for="middle-name"
                        >
                          Middle Name
                        </label>
                        <b-form-input
                          id="middle-name"
                          v-model="middleName"
                          type="text"
                          placeholder="Middle Name"
                        />
                      </b-form-group>

                    </b-col>
                  </b-row>

                  <b-row>

                    <!-- Input AKA Last Name -->
                    <b-col
                      cols="12"
                      lg="4"
                    >

                      <b-form-group
                        label="AKA Last Name"
                        label-for="aka-last-name"
                      >
                        <label
                          class="sr-only"
                          for="aka-last-name"
                        >
                          AKA Last Name
                        </label>
                        <b-form-input
                          id="aka-last-name"
                          v-model="akaLastName"
                          type="text"
                          placeholder="AKA Last Name"
                        />
                      </b-form-group>

                    </b-col>

                    <!-- Input AKA First Name -->
                    <b-col
                      cols="12"
                      lg="4"
                    >

                      <b-form-group
                        label="AKA First Name"
                        label-for="aka-first-name"
                      >
                        <label
                          class="sr-only"
                          for="aka-first-name"
                        >
                          AKA First Name
                        </label>
                        <b-form-input
                          id="aka-first-name"
                          v-model="akaFirstName"
                          type="text"
                          placeholder="AKA First Name"
                        />
                      </b-form-group>

                    </b-col>
                  </b-row>

                  <!-- Spacer -->
                  <br>

                  <b-row>

                    <!-- Input Date of Birth -->
                    <b-col
                      cols="12"
                      lg="4"
                    >

                      <validation-provider
                        #default="{ errors }"
                        name="Date of Birth"
                        vid="date-of-birth"
                        rules="required"
                      >
                        <b-form-group
                          label="* Date of Birth"
                          label-for="date-of-birth"
                          :state="errors.length > 0 ? false : null"
                        >
                          <label
                            class="sr-only"
                            for="date-of-birth"
                          >
                            * Date of Birth
                          </label>
                          <flat-pickr
                            id="date-of-birth"
                            v-model="dateOfBirth"
                            class="form-control"
                            :config="flatPickrConfig"
                            placeholder="Choose a date"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </b-form-group>
                      </validation-provider>

                    </b-col>

                    <!-- Input Place of Birth -->
                    <b-col
                      cols="12"
                      lg="8"
                    >

                      <b-form-group
                        label="* Place of Birth"
                        label-for="place-of-birth"
                      >
                        <label
                          class="sr-only"
                          for="place-of-birth"
                        >
                          * Place of Birth
                        </label>
                        <validation-provider
                          #default="{ errors }"
                          name="Place of Birth"
                          vid="place-of-birth"
                          rules="required"
                        >
                          <b-form-input
                            id="place-of-birth"
                            v-model="placeOfBirth"
                            :state="errors.length > 0 ? false : null"
                            type="text"
                            placeholder="Place of Birth"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>

                    </b-col>
                  </b-row>

                  <!-- Spacer -->
                  <br>

                  <b-row>

                    <!-- Input Sex -->
                    <b-col
                      cols="12"
                      lg="4"
                    >

                      <validation-provider
                        #default="{ errors }"
                        name="Sex"
                        rules="required"
                      >
                        <b-form-group
                          label="* Sex"
                          label-for="sex"
                          :state="errors.length > 0 ? false : null"
                        >
                          <label
                            class="sr-only"
                            for="sex"
                          >
                            * Sex
                          </label>
                          <v-select
                            v-model="sex"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="sexOptions"
                            placeholder="Select Sex"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </b-form-group>
                      </validation-provider>

                    </b-col>
                  </b-row>

                  <!-- Spacer -->
                  <br>

                  <b-row>

                    <!-- Input Weight -->
                    <b-col
                      cols="6"
                      lg="2"
                    >

                      <b-form-group
                        label="* Weight (lb)"
                        label-for="weight"
                      >
                        <label
                          class="sr-only"
                          for="weight"
                        >
                          * Weight (lb)
                        </label>
                        <validation-provider
                          #default="{ errors }"
                          name="Weight"
                          vid="weight"
                          rules="required"
                        >
                          <b-form-input
                            id="weight"
                            v-model="weight"
                            :state="errors.length > 0 ? false : null"
                            type="number"
                            step="0.1"
                            placeholder="Weight"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>

                    </b-col>

                    <!-- Input Height -->
                    <b-col
                      cols="6"
                      lg="2"
                    >

                      <b-form-group
                        label="* Height (cm)"
                        label-for="weight"
                      >
                        <label
                          class="sr-only"
                          for="weight"
                        >
                          * Height (cm)
                        </label>
                        <validation-provider
                          #default="{ errors }"
                          name="Height"
                          vid="height"
                          rules="required"
                        >
                          <b-form-input
                            id="height"
                            v-model="height"
                            :state="errors.length > 0 ? false : null"
                            type="number"
                            step="0.1"
                            placeholder="Height"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>

                    </b-col>

                    <!-- Input Eye Color -->
                    <b-col
                      cols="12"
                      lg="4"
                    >

                      <validation-provider
                        #default="{ errors }"
                        name="Eye Color"
                        rules="required"
                      >
                        <b-form-group
                          label="* Eye Color"
                          label-for="eye-color"
                          :state="errors.length > 0 ? false : null"
                        >
                          <label
                            class="sr-only"
                            for="eye-color"
                          >
                            * Eye Color
                          </label>
                          <v-select
                            v-model="eyeColor"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="eyeColorOptions"
                            multiple
                            taggable
                            push-tags
                            placeholder="Select or Type Eye Color"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </b-form-group>
                      </validation-provider>

                    </b-col>

                    <!-- Input Hair Color -->
                    <b-col
                      cols="12"
                      lg="4"
                    >

                      <validation-provider
                        #default="{ errors }"
                        name="Hair Color"
                        rules="required"
                      >
                        <b-form-group
                          label="* Hair Color"
                          label-for="hair-color"
                          :state="errors.length > 0 ? false : null"
                        >
                          <label
                            class="sr-only"
                            for="hair-color"
                          >
                            * Hair Color
                          </label>
                          <v-select
                            v-model="hairColor"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="hairColorOptions"
                            multiple
                            taggable
                            push-tags
                            placeholder="Select or Type Hair Color"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </b-form-group>
                      </validation-provider>

                    </b-col>
                  </b-row>

                  <!-- Spacer -->
                  <br>

                  <b-row>

                    <!-- Input Street Number and/or Name -->
                    <b-col
                      cols="12"
                      lg="8"
                    >

                      <b-form-group
                        label="* Street Number and/or Name"
                        label-for="street"
                      >
                        <label
                          class="sr-only"
                          for="street"
                        >
                          * Street Number and/or Name
                        </label>
                        <validation-provider
                          #default="{ errors }"
                          name="Street Number and/or Name"
                          vid="street"
                          rules="required"
                        >
                          <b-form-input
                            id="street"
                            v-model="street"
                            :state="errors.length > 0 ? false : null"
                            type="text"
                            placeholder="Street Number and/or Name"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>

                    </b-col>

                    <!-- Input Street or POB -->
                    <b-col
                      cols="12"
                      lg="4"
                    >

                      <b-form-group
                        label="Street or POB"
                        label-for="street-pob"
                      >
                        <label
                          class="sr-only"
                          for="street-pob"
                        >
                          Street or POB
                        </label>
                        <b-form-input
                          id="street-pob"
                          v-model="streetPob"
                          type="text"
                          placeholder="Street or POB"
                        />
                      </b-form-group>

                    </b-col>
                  </b-row>

                  <b-row>

                    <!-- Input City or Town -->
                    <b-col
                      cols="12"
                      lg="4"
                    >

                      <b-form-group
                        label="* City or Town"
                        label-for="city-town"
                      >
                        <label
                          class="sr-only"
                          for="city-town"
                        >
                          * City or Town
                        </label>
                        <validation-provider
                          #default="{ errors }"
                          name="City or Town"
                          vid="city-town"
                          rules="required"
                        >
                          <b-form-input
                            id="city-town"
                            v-model="cityTown"
                            :state="errors.length > 0 ? false : null"
                            type="text"
                            placeholder="City or Town"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>

                    </b-col>

                    <!-- Input State -->
                    <b-col
                      cols="12"
                      lg="4"
                    >

                      <b-form-group
                        label="* State"
                        label-for="state"
                      >
                        <label
                          class="sr-only"
                          for="state"
                        >
                          * State
                        </label>
                        <validation-provider
                          #default="{ errors }"
                          name="State"
                          vid="state"
                          rules="required"
                        >
                          <b-form-input
                            id="state"
                            v-model="state"
                            :state="errors.length > 0 ? false : null"
                            type="text"
                            placeholder="State"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>

                    </b-col>

                    <!-- Input Zip Code -->
                    <b-col
                      cols="12"
                      lg="4"
                    >

                      <b-form-group
                        label="* Zip Code"
                        label-for="zip-code"
                      >
                        <label
                          class="sr-only"
                          for="zip-code"
                        >
                          * Zip Code
                        </label>
                        <validation-provider
                          #default="{ errors }"
                          name="Zip Code"
                          vid="zip-code"
                          rules="required|regex:^[\-0-9]+$"
                        >
                          <b-form-input
                            id="zip-code"
                            v-model="zipCode"
                            :state="errors.length > 0 ? false : null"
                            type="text"
                            placeholder="Zip Code"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>

                    </b-col>
                  </b-row>

                  <!-- Spacer -->
                  <br>

                  <b-row>

                    <!-- Input SSN -->
                    <b-col
                      cols="12"
                      lg="4"
                    >

                      <b-form-group
                        label="SSN"
                        label-for="ssn"
                      >
                        <label
                          class="sr-only"
                          for="ssn"
                        >
                          SSN
                        </label>
                        <b-form-input
                          id="ssn"
                          v-model="ssn"
                          type="text"
                          placeholder="SSN"
                        />
                      </b-form-group>

                    </b-col>

                    <!-- Input TIN -->
                    <b-col
                      cols="12"
                      lg="4"
                    >

                      <b-form-group
                        label="TIN"
                        label-for="tin"
                      >
                        <label
                          class="sr-only"
                          for="tin"
                        >
                          TIN
                        </label>
                        <b-form-input
                          id="tin"
                          v-model="tin"
                          type="text"
                          placeholder="TIN"
                        />
                      </b-form-group>

                    </b-col>

                    <!-- Input CDL -->
                    <b-col
                      cols="12"
                      lg="4"
                    >

                      <b-form-group
                        label="CDL"
                        label-for="cdl"
                      >
                        <label
                          class="sr-only"
                          for="cdl"
                        >
                          CDL
                        </label>
                        <b-form-input
                          id="cdl"
                          v-model="cdl"
                          type="text"
                          placeholder="CDL"
                        />
                      </b-form-group>

                    </b-col>
                  </b-row>

                  <!-- Spacer -->
                  <br>

                  <b-row>

                    <!-- Input Misc. Number -->
                    <b-col
                      cols="12"
                      lg="4"
                    >

                      <b-form-group
                        label="Misc. Number"
                        label-for="misc-number"
                      >
                        <label
                          class="sr-only"
                          for="misc-number"
                        >
                          Misc. Number
                        </label>
                        <b-form-input
                          id="misc-number"
                          v-model="miscNumber"
                          type="text"
                          placeholder="Misc. Number"
                        />
                      </b-form-group>

                    </b-col>

                    <!-- Input Misc. Number Bil -->
                    <b-col
                      cols="12"
                      lg="4"
                    >

                      <b-form-group
                        label="Misc. Number Bil"
                        label-for="misc-number-bil"
                      >
                        <label
                          class="sr-only"
                          for="misc-number-bil"
                        >
                          Misc. Number Bil
                        </label>
                        <b-form-input
                          id="misc-number-bil"
                          v-model="miscNumberBil"
                          type="text"
                          placeholder="Misc. Number Bil"
                        />
                      </b-form-group>

                    </b-col>
                  </b-row>

                  <!-- Spacer -->
                  <hr>

                  <h4>Section 6: Facility Number</h4>

                  <b-row>

                    <!-- Input Facility/Organization Number -->
                    <b-col
                      cols="12"
                      lg="4"
                    >

                      <b-form-group
                        label="* Facility/Organization Number"
                        label-for="facility-organization-number"
                      >
                        <label
                          class="sr-only"
                          for="facility-organization-number"
                        >
                          * Facility/Organization Number
                        </label>
                        <validation-provider
                          #default="{ errors }"
                          name="Facility/Organization Number"
                          vid="facility-organization-number"
                          rules="required"
                        >
                          <b-form-input
                            id="facility-organization-number"
                            v-model="form.facilityOrganizationNumber"
                            :state="errors.length > 0 ? false : null"
                            type="number"
                            placeholder="Facility/Organization Number"
                            readonly
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>

                    </b-col>

                    <!-- Checkbox Level of Service -->
                    <b-col
                      cols="12"
                      lg="8"
                    >

                      <b-form-group
                        label="* Level of Service"
                        label-for="level-of-service"
                      >
                        <label
                          class="sr-only"
                          for="level-of-service"
                        >
                          * Level of Service
                        </label>
                        <validation-provider
                          #default="{ errors }"
                          name="Level of Service"
                          rules="required"
                        >
                          <b-form-checkbox-group
                            ref="levelOfService"
                            v-model="form.levelOfService"
                            :state="errors.length > 0 ? false : null"
                            :options="['DOJ', 'FBI']"
                            class="mt-1"
                            title="Level of Service"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>

                    </b-col>
                  </b-row>

                  <p><b>Note:</b> <b>If a Child Abuse Central Index (CACI) check is required, it will automatically be completed by DOJ and all applicable fees will be charged. There is no entry necessary on the applicant's part.</b></p>

                  <!-- Spacer -->
                  <br>

                  <b-row>

                    <!-- Input Original ATI Number -->
                    <b-col
                      cols="12"
                      lg="8"
                    >

                      <b-form-group
                        label="If resubmission for fingerprint quality (select R2), list Original ATI Number."
                        label-for="original-ati-number"
                      >
                        <label
                          class="sr-only"
                          for="original-ati-number"
                        >
                          If resubmission for fingerprint quality (select R2), list Original ATI Number.
                        </label>
                        <b-form-input
                          id="original-ati-number"
                          ref="originalAtiNumber"
                          v-model="form.originalAtiNumber"
                          type="text"
                          placeholder="Original ATI Number"
                          title="Original ATI Number"
                        />
                      </b-form-group>

                    </b-col>
                  </b-row>

                  <p><b>If resubmission for fingerprint quality, list Original Applicant Tracking Information (ATI) No.:</b> If your fingerprints were rejected and this is a resubmission of your prints, enter the original ATI number provided on the reject notice to avoid paying an additional processing fee.</p>

                  <!-- Spacer -->
                  <hr>

                  <h4>Section 7: Employer <em>(Additional response for Department of Social Services, DMV/C P licensing, and Department of Corporations submissions only.)</em></h4>

                  <b-row>

                    <!-- Input Employer -->
                    <b-col
                      cols="12"
                      lg="8"
                    >

                      <b-form-group
                        label="* Employer"
                        label-for="employer"
                      >
                        <label
                          class="sr-only"
                          for="employer"
                        >
                          * Employer
                        </label>
                        <validation-provider
                          #default="{ errors }"
                          name="Employer"
                          vid="employer"
                          rules="required"
                        >
                          <b-form-input
                            id="employer"
                            ref="employer"
                            v-model="form.employer"
                            :state="errors.length > 0 ? false : null"
                            type="text"
                            placeholder="Employer"
                            title="Employer"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>

                    </b-col>

                    <!-- Input Employer Mail Code -->
                    <b-col
                      cols="12"
                      lg="4"
                    >

                      <b-form-group
                        label="* Mail Code (5-digit code assigned by DOJ.)"
                        label-for="employer-mail-code"
                      >
                        <label
                          class="sr-only"
                          for="employer-mail-code"
                        >
                          * Mail Code
                        </label>
                        <validation-provider
                          #default="{ errors }"
                          name="Mail Code (5-digit code assigned by DOJ.)"
                          vid="employer-mail-code"
                          rules="required"
                        >
                          <b-form-input
                            id="employer-mail-code"
                            ref="employerMailCode"
                            v-model="form.employerMailCode"
                            :state="errors.length > 0 ? false : null"
                            type="text"
                            placeholder="Mail Code"
                            title="Employer Mail Code"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>

                    </b-col>
                  </b-row>

                  <!-- Spacer -->
                  <br>

                  <b-row>

                    <!-- Input Employer Street Number and/or Name -->
                    <b-col
                      cols="12"
                      lg="8"
                    >

                      <b-form-group
                        label="* Street Number and/or Name"
                        label-for="employer-street"
                      >
                        <label
                          class="sr-only"
                          for="employer-street"
                        >
                          * Street Number and/or Name
                        </label>
                        <validation-provider
                          #default="{ errors }"
                          name="Street Number and/or Name"
                          vid="employer-street"
                          rules="required"
                        >
                          <b-form-input
                            id="employer-street"
                            ref="employerStreet"
                            v-model="form.employerStreet"
                            :state="errors.length > 0 ? false : null"
                            type="text"
                            placeholder="Street Number and/or Name"
                            title="Employer Street Number and/or Name"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>

                    </b-col>

                    <!-- Input Employer Street or POB -->
                    <b-col
                      cols="12"
                      lg="4"
                    >

                      <b-form-group
                        label="Street or POB"
                        label-for="employer-street-pob"
                      >
                        <label
                          class="sr-only"
                          for="employer-street-pob"
                        >
                          Street or POB
                        </label>
                        <b-form-input
                          id="employer-street-pob"
                          ref="employerStreetPob"
                          v-model="form.employerStreetPob"
                          type="text"
                          placeholder="Street or POB"
                          title="Employer Street or POB"
                        />
                      </b-form-group>

                    </b-col>
                  </b-row>

                  <b-row>

                    <!-- Input Employer City or Town -->
                    <b-col
                      cols="12"
                      lg="4"
                    >

                      <b-form-group
                        label="* City or Town"
                        label-for="employer-city-town"
                      >
                        <label
                          class="sr-only"
                          for="employer-city-town"
                        >
                          * City or Town
                        </label>
                        <validation-provider
                          #default="{ errors }"
                          name="City or Town"
                          vid="employer-city-town"
                          rules="required"
                        >
                          <b-form-input
                            id="employer-city-town"
                            ref="employerCityTown"
                            v-model="form.employerCityTown"
                            :state="errors.length > 0 ? false : null"
                            type="text"
                            placeholder="City or Town"
                            title="Employer City or Town"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>

                    </b-col>

                    <!-- Input Employer State -->
                    <b-col
                      cols="12"
                      lg="4"
                    >

                      <b-form-group
                        label="* State"
                        label-for="employer-state"
                      >
                        <label
                          class="sr-only"
                          for="employer-state"
                        >
                          * State
                        </label>
                        <validation-provider
                          #default="{ errors }"
                          name="State"
                          vid="employer-state"
                          rules="required"
                        >
                          <b-form-input
                            id="employer-state"
                            ref="employerState"
                            v-model="form.employerState"
                            :state="errors.length > 0 ? false : null"
                            type="text"
                            placeholder="State"
                            title="Employer State"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>

                    </b-col>

                    <!-- Input Employer Zip Code -->
                    <b-col
                      cols="12"
                      lg="4"
                    >

                      <b-form-group
                        label="* Zip Code"
                        label-for="employer-zip-code"
                      >
                        <label
                          class="sr-only"
                          for="employer-zip-code"
                        >
                          * Zip Code
                        </label>
                        <validation-provider
                          #default="{ errors }"
                          name="Zip Code"
                          vid="employer-zip-code"
                          rules="required|regex:^[\-0-9]+$"
                        >
                          <b-form-input
                            id="employer-zip-code"
                            ref="employerZipCode"
                            v-model="form.employerZipCode"
                            :state="errors.length > 0 ? false : null"
                            type="text"
                            placeholder="Zip Code"
                            title="Employer Zip Code"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>

                    </b-col>
                  </b-row>

                  <!-- Spacer -->
                  <br>

                  <b-row>

                    <!-- Input Agency Phone Number -->
                    <b-col
                      cols="12"
                      lg="4"
                    >

                      <b-form-group
                        label="Agency Phone Number: (Optional)"
                        label-for="agency-phone-number"
                      >
                        <label
                          class="sr-only"
                          for="agency-phone-number"
                        >
                          Agency Phone Number: (Optional)
                        </label>
                        <b-input-group>
                          <b-input-group-prepend is-text>
                            US (+1)
                          </b-input-group-prepend>
                          <b-form-input
                            id="agency-phone-number"
                            ref="agencyPhoneNumber"
                            v-model="form.agencyPhoneNumber"
                            v-mask="['(###) ###-####']"
                            type="tel"
                            placeholder="Agency Phone Number"
                            title="Agency Phone Number"
                          />
                        </b-input-group>
                      </b-form-group>

                    </b-col>
                  </b-row>

                  <!-- Spacer -->
                  <hr>

                  <h4>Live Scan Transaction Completed By <em>(This section will be completed by the Live Scan operator.)</em></h4>

                  <b-row>

                    <!-- Input Operator -->
                    <b-col
                      cols="12"
                      lg="8"
                    >

                      <b-form-group
                        label="* Name of Operator"
                        label-for="operator"
                      >
                        <label
                          class="sr-only"
                          for="operator"
                        >
                          * Name of Operator
                        </label>
                        <validation-provider
                          #default="{ errors }"
                          name="Name of Operator"
                          vid="operator"
                          rules="required"
                        >
                          <b-form-input
                            id="operator"
                            ref="operator"
                            v-model="form.operator"
                            :state="errors.length > 0 ? false : null"
                            type="text"
                            placeholder="Name of Operator"
                            title="Name of Operator"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>

                    </b-col>

                    <!-- Input Date Completed -->
                    <b-col
                      cols="12"
                      lg="4"
                    >

                      <validation-provider
                        #default="{ errors }"
                        name="Date"
                        vid="date-completed"
                        rules="required"
                      >
                        <b-form-group
                          label="* Date"
                          label-for="date-completed"
                          :state="errors.length > 0 ? false : null"
                        >
                          <label
                            class="sr-only"
                            for="date-completed"
                          >
                            * Date
                          </label>
                          <flat-pickr
                            id="date-completed"
                            ref="dateCompleted"
                            v-model="form.dateCompleted"
                            class="form-control"
                            :config="flatPickrConfig"
                            placeholder="Choose a date"
                            title="Date Completed"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </b-form-group>
                      </validation-provider>

                    </b-col>
                  </b-row>

                  <!-- Spacer -->
                  <br>

                  <b-row>

                    <!-- Input Transmitting Agency -->
                    <b-col
                      cols="12"
                      lg="8"
                    >

                      <b-form-group
                        label="* Transmitting Agency"
                        label-for="transmitting-agency"
                      >
                        <label
                          class="sr-only"
                          for="transmitting-agency"
                        >
                          * Transmitting Agency
                        </label>
                        <validation-provider
                          #default="{ errors }"
                          name="Transmitting Agency"
                          vid="transmitting-agency"
                          rules="required"
                        >
                          <b-form-input
                            id="transmitting-agency"
                            ref="transmittingAgency"
                            v-model="form.transmittingAgency"
                            :state="errors.length > 0 ? false : null"
                            type="text"
                            placeholder="Transmitting Agency"
                            title="Transmitting Agency"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>

                    </b-col>

                    <!-- Input LSID -->
                    <b-col
                      cols="12"
                      lg="4"
                    >

                      <b-form-group
                        label="* LSID"
                        label-for="lsid"
                      >
                        <label
                          class="sr-only"
                          for="lsid"
                        >
                          * LSID
                        </label>
                        <validation-provider
                          #default="{ errors }"
                          name="LSID"
                          vid="lsid"
                          rules="required"
                        >
                          <b-form-input
                            id="lsid"
                            ref="lsid"
                            v-model="form.lsid"
                            :state="errors.length > 0 ? false : null"
                            type="text"
                            placeholder="LSID"
                            title="LSID"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>

                    </b-col>
                  </b-row>

                  <b-row>

                    <!-- Input ATI Number -->
                    <b-col
                      cols="12"
                      lg="8"
                    >

                      <b-form-group
                        label="* ATI Number"
                        label-for="ati-number"
                      >
                        <label
                          class="sr-only"
                          for="ati-number"
                        >
                          * ATI Number
                        </label>
                        <validation-provider
                          #default="{ errors }"
                          name="ATI Number"
                          vid="ati-number"
                          rules="required"
                        >
                          <b-form-input
                            id="ati-number"
                            ref="atiNumber"
                            v-model="form.atiNumber"
                            :state="errors.length > 0 ? false : null"
                            type="text"
                            placeholder="ATI Number"
                            title="ATI Number"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>

                    </b-col>

                    <!-- Input Amount Collected/Billed -->
                    <b-col
                      cols="12"
                      lg="4"
                    >

                      <b-form-group
                        label="* Amount Collected/Billed"
                        label-for="amount-collected-billed"
                      >
                        <label
                          class="sr-only"
                          for="amount-collected-billed"
                        >
                          * Amount Collected/Billed
                        </label>
                        <validation-provider
                          #default="{ errors }"
                          name="Amount Collected/Billed"
                          vid="amount-collected-billed"
                          rules="required"
                        >
                          <b-input-group>
                            <b-input-group-prepend is-text>
                              $
                            </b-input-group-prepend>
                            <b-form-input
                              id="amount-collected-billed"
                              ref="amountCollectedBilled"
                              v-model="form.amountCollectedBilled"
                              :state="errors.length > 0 ? false : null"
                              type="number"
                              step="0.1"
                              placeholder="Amount Collected/Billed"
                              title="Amount Collected/Billed"
                            />
                          </b-input-group>
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>

                    </b-col>
                  </b-row>

                  <p><b>Take two copies of this form with you the day you are fingerprinted. The Live Scan Operator will complete section 8. One copy will be retained by the Operator and the other you may retain for your records.</b></p>

                  <!-- Spacer -->
                  <hr>

                  <h4 class="text-center">
                    PRIVACY STATEMENT
                  </h4>
                  <p>Pursuant to the Federal Privacy Act (P.L. 93-579) and the Information Practices Act of 1977 (Civil Code section 1798 et seq.), notice is given for the request of the Social Security Number (SSN) on this form. The California Department of Justice uses a person’s SSN as an identifying number. The requested SSN is voluntary. Failure to provide the SSN may delay the processing of this form and the criminal record check.</p>
                  <p>In order to be licensed, work at, or be present at, a licensed facility organization, the law requires that you complete a criminal background check. (Health and Safety Code sections 1522, 1568.09, 1569.17 and 1596.871). The Department will create a file concerning your criminal background check that will contain certain documents, including information that you provide. You have the right to access certain records containing your personal information maintained by the Department (Civil Code section 1798 et seq.). Under the California Public Records Act, the Department may have to provide copies of some of the records in the file to members of the public who ask for them, including newspaper and television reporters.</p>
                  <p>NOTE: IMPORTANT INFORMATION<br>The Department is required to tell people who ask, including the press, if someone in a licensed facility organization has a criminal record exemption. The Department must also tell people who ask the name of a licensed facility organization that has a licensee, employee, resident, or other person with a criminal record exemption.</p>
                  <p>If you have any questions about this form, please contact your local licensing regional office.</p>

                  <!-- Button Submit -->
                  <b-button
                    variant="primary"
                    type="submit"
                    class="mr-1 d-none"
                    @click.prevent="validationForm"
                  >
                    Submit
                  </b-button>
                </b-form>
              </validation-observer>

            </b-col>
          </b-row>

        </b-card>

      </b-overlay>
    </b-col>

    <!-- Right -->
    <b-col
      cols="12"
      lg="3"
    >

      <!-- Action Buttons -->
      <b-card>

        <!-- Button Preview -->
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="gradient-main"
          class="mb-75 btn-print-preview"
          block
          @click="$store.dispatch('app-hr/preview')"
        >
          Print Preview
        </b-button>

        <!-- Button Save -->
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="secondary"
          block
          :disabled="(userData.role === 'agent' && formUpdateStatus > 0) ? true : isNotSaved"
          @click.prevent="validationForm"
        >
          Save
        </b-button>

        <!-- Button Cancel -->
        <b-button
          v-if="action !== 'preview'"
          variant="outline-dark"
          class="mb-75"
          :to="{ name: (userData.role === 'agent' ? 'caregiverLanding' : 'hr') }"
          block
        >
          Cancel
        </b-button>

        <div class="d-flex">
          <!-- Button Previous -->
          <b-button
            variant="outline-dark"
            class="flex-fill mr-25"
            :to="{ name: 'apps-hr-form9', params: { action: action }, query: { id: id } }"
          >
            Previous
          </b-button>

          <!-- Button Next -->
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="secondary"
            class="flex-fill ml-25"
            :to="{ name: 'apps-hr-form11', params: { action: action }, query: { id: id } }"
          >
            Next
          </b-button>
        </div>

        <!-- Spacer -->
        <hr class="mb-50">

        <label for="forms">Form Selection</label>
        <v-select
          id="forms"
          v-model="forms"
          :reduce="(option) => option.name"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          :options="formsOptions"
          :clearable="false"
          :disabled="!id"
          class="hr-forms-select"
          @input="$router.push({ name: forms, params: { action: action }, query: { id: id } })"
        >
          <template #selected-option="{ label }">
            <span class="text-truncate overflow-hidden">
              {{ label }}
            </span>
          </template>
        </v-select>
      </b-card>

    </b-col>
  </b-row>

</template>

<script>
import SockJS from 'sockjs-client'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BOverlay, BAlert, BCard, BCardTitle, BRow, BCol, BForm, BFormGroup, BFormInput, BInputGroup, BInputGroupPrepend, BFormCheckboxGroup, BFormRadio, BTable, BButton, VBToggle,
} from 'bootstrap-vue'
import { required } from '@validations'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import { mask } from 'vue-the-mask'
import Swal from 'sweetalert2'
import { ref, onUnmounted } from '@vue/composition-api'
import store from '@/store'
import router from '@/router'

import { getUserData } from '@/auth/utils'

import hrStoreModule from '../hrStoreModule'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,

    BOverlay,
    BAlert,
    BCard,
    BCardTitle,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BFormCheckboxGroup,
    BFormRadio,
    BTable,
    BButton,

    vSelect,
    flatPickr,
    // eslint-disable-next-line
    Swal,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
    mask,
  },
  setup() {
    const HR_APP_STORE_MODULE_NAME = 'app-hr'

    // Register module
    if (!store.hasModule(HR_APP_STORE_MODULE_NAME)) store.registerModule(HR_APP_STORE_MODULE_NAME, hrStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(HR_APP_STORE_MODULE_NAME)) store.unregisterModule(HR_APP_STORE_MODULE_NAME)
    })

    const form = ref({})

    const lastName = ref('')
    const firstName = ref('')
    const middleName = ref('')
    const akaLastName = ref('')
    const akaFirstName = ref('')
    const dateOfBirth = ref('')
    const placeOfBirth = ref('')
    const sex = ref('')
    const weight = ref('')
    const height = ref('')
    const eyeColor = ref('')
    const hairColor = ref('')
    const street = ref('')
    const streetPob = ref('')
    const cityTown = ref('')
    const state = ref('')
    const zipCode = ref('')
    const ssn = ref('')
    const tin = ref('')
    const cdl = ref('')
    const miscNumber = ref('')
    const miscNumberBil = ref('')

    const formUpdate = ref({})
    const formUpdateStatus = ref(0)

    const formCopy = ref({})

    if (router.currentRoute.query.id) {
      store.dispatch('app-hr/fetchForm', { id: router.currentRoute.query.id })
        .then(response => {
          // eslint-disable-next-line
          const jsonObjectLiterals = response.data.response.data

          // console.log(jsonObjectLiterals.data)

          form.value = {}

          form.value.cid = router.currentRoute.query.id

          form.value.ori = 'A0448'
          // form.value.workingTitle = 'Employee'
          form.value.agency = 'CA Dept of Social Services'
          form.value.agencyMailCode = '03502'
          form.value.agencyStreet = 'PO BOX 94244'
          form.value.agencyStreetPob = 'Mail Station 9-15-62'
          form.value.agencyCityTown = 'Sacramento'
          form.value.agencyState = 'CA'
          form.value.agencyZipCode = '94244-2430'
          form.value.facilityOrganizationNumber = '304700215'
          form.value.levelOfService = ['DOJ', 'FBI']
          form.value.employer = '24/7 CIRCLE OF CARE, LLC'
          form.value.employerMailCode = '21432'
          form.value.employerStreet = '34145 Pacific Coast Hwy'
          form.value.employerStreetPob = 'Unit 228'
          form.value.employerCityTown = 'Dana Point'
          form.value.employerState = 'CA'
          form.value.employerZipCode = '92629'
          form.value.agencyPhoneNumber = '9495039114'

          lastName.value = jsonObjectLiterals.data.lastName
          firstName.value = jsonObjectLiterals.data.firstName
          middleName.value = jsonObjectLiterals.data.middleName
          akaLastName.value = jsonObjectLiterals.data.akaLastName
          akaFirstName.value = jsonObjectLiterals.data.akaFirstName
          dateOfBirth.value = jsonObjectLiterals.data.dateOfBirth
          placeOfBirth.value = jsonObjectLiterals.data.placeOfBirth
          sex.value = jsonObjectLiterals.data.sex
          weight.value = jsonObjectLiterals.data.weight
          height.value = jsonObjectLiterals.data.height
          eyeColor.value = jsonObjectLiterals.data.eyeColor
          hairColor.value = jsonObjectLiterals.data.hairColor
          street.value = jsonObjectLiterals.data.street
          streetPob.value = jsonObjectLiterals.data.streetPob
          cityTown.value = jsonObjectLiterals.data.cityTown
          state.value = jsonObjectLiterals.data.state
          zipCode.value = jsonObjectLiterals.data.zipCode
          ssn.value = jsonObjectLiterals.data.ssn
          tin.value = jsonObjectLiterals.data.tin
          cdl.value = jsonObjectLiterals.data.cdl
          miscNumber.value = jsonObjectLiterals.data.miscNumber
          miscNumberBil.value = jsonObjectLiterals.data.miscNumberBil

          if (router.currentRoute.params.action === 'edit' || (router.currentRoute.params.action === 'add' && router.currentRoute.query.id)) {
            store.dispatch('app-hr/fetchForm10', { cid: router.currentRoute.query.id })
              .then(response1 => {
                // eslint-disable-next-line
                const jsonObjectLiterals = response1.data.response.data

                // console.log(jsonObjectLiterals.data)

                if (jsonObjectLiterals.data.cid) {
                  form.value = jsonObjectLiterals.data
                }

                form.value.workingTitle = jsonObjectLiterals.data.workingTitle ? jsonObjectLiterals.data.workingTitle : 'Employee'

                if (form.value.id) {
                  form.value.form = 'folder3_form10'

                  formCopy.value = JSON.parse(JSON.stringify(form.value))

                  store.dispatch('app-hr/fetchFormUpdate', { form: form.value.form, form_id: form.value.id })
                    .then(response2 => {
                      // eslint-disable-next-line
                      const jsonObjectLiterals = response2.data.response.data

                      // console.log(jsonObjectLiterals.data)

                      if (jsonObjectLiterals.data.status) {
                        formUpdate.value = jsonObjectLiterals.data

                        formUpdateStatus.value = formUpdate.value.status
                      }
                    })
                    .catch(error => {
                      console.error(error)
                    })
                }
              })
              .catch(error => {
                console.error(error)
              })
          }
        })
        .catch(error => {
          console.error(error)
        })
    }

    const userData = ref(getUserData())

    const authorizedApplicantTypeOptions = [
      'Home Care Aide',
      'Home Care Organization',
      'Adult Day/Resident/Rehab',
      'Day Care Center more/6 Child',
      'Family Day Care',
      'Foster Family/Adopt Employment',
      'Foster Family Home',
      'Group Home 6/child less',
      'Group Home more/6 child',
      'Residential Care Facility Elderly',
      'Residential Child Care 6/less',
    ]

    const sexOptions = [
      'Male',
      'Female',
    ]

    const eyeColorOptions = [
      'Brown',
      'Blue',
      'Hazel',
      'Amber',
      'Gray',
      'Green',
    ]

    const hairColorOptions = [
      'Black',
      'Brown',
      'Blonde',
      'Auburn',
      'Red',
      'Chestnut',
      'Gray',
      'White',
    ]

    const formsOptions = [
      { label: 'Basic Information', name: 'apps-hr-form' },
      { label: 'Employment Checklist', name: 'apps-hr-form1' },
      { label: 'Employment Application', name: 'apps-hr-form2' },
      { label: 'Employment Skills List', name: 'apps-hr-form3' },
      { label: 'Work Experiences Checklist', name: 'apps-hr-form4' },
      { label: 'Employment Eligibility Verification', name: 'apps-hr-form5' },
      { label: 'Form W-4', name: 'apps-hr-form6' },
      { label: 'Non-Compete and Non-Solicitation Agreement', name: 'apps-hr-form7' },
      { label: 'Employment Verification Fax', name: 'apps-hr-form8' },
      { label: 'Ref Inquiry Form', name: 'apps-hr-form9' },
      { label: 'Request for Live Scan Service - Community Care Licensing', name: 'apps-hr-form10' },
      { label: 'New Hire Orientation Acknowledgment Form', name: 'apps-hr-form11' },
      { label: 'Employee Tuberculin Skin Test (TST) and Evaluation', name: 'apps-hr-form12' },
      { label: 'Home Care Organization Inspection Checklist', name: 'apps-hr-form13' },
      { label: 'Personnel Record (Admin)', name: 'apps-hr-form14a' },
      { label: 'Personnel Record', name: 'apps-hr-form14b' },
      { label: 'Applicant for Home Care Aide Registration', name: 'apps-hr-form14c' },
      { label: 'Criminal Record Statement', name: 'apps-hr-form15' },
      { label: 'Statement Acknowledging Requirement to Report Suspected Abuse of Dependent Adults and Elders', name: 'apps-hr-form16' },
      { label: 'Registered Home Care Aide Training Log A', name: 'apps-hr-form17a' },
      { label: 'Registered Home Care Aide Training Log B', name: 'apps-hr-form17b' },
      { label: 'Workplace Safety', name: 'apps-hr-form18' },
      { label: 'Current Knowledge', name: 'apps-hr-form19' },
      { label: 'Fact Sheet (Home Care Services - April 2017)', name: 'apps-hr-form20' },
      { label: 'Acknowledgement Form - Fact Sheet (Home Care Services)', name: 'apps-hr-form21' },
      { label: 'Training Completion Certificate', name: 'apps-hr-form22' },
    ]

    const flatPickrConfig = {
      // wrap: true, // set wrap to true only when using 'input-group'
      // altFormat: 'm/d/Y',
      // altInput: true,
      dateFormat: 'Y-m-d',
    }

    const sock = new SockJS('https://socket.motivit.com:443/echo')
    const sockId = 'coc-n01'

    return {
      form,

      lastName,
      firstName,
      middleName,
      akaLastName,
      akaFirstName,
      dateOfBirth,
      placeOfBirth,
      sex,
      weight,
      height,
      eyeColor,
      hairColor,
      street,
      streetPob,
      cityTown,
      state,
      zipCode,
      ssn,
      tin,
      cdl,
      miscNumber,
      miscNumberBil,

      formUpdate,
      formUpdateStatus,

      formCopy,

      authorizedApplicantTypeOptions,
      sexOptions,
      eyeColorOptions,
      hairColorOptions,

      userData,

      formsOptions,

      flatPickrConfig,

      sock,
      sockId,
    }
  },
  data() {
    return {
      action: this.$route.params.action,
      id: this.$route.query.id,

      fields: [
        {
          key: 'first',
        },
        {
          key: 'last',
          tdClass: 'text-right',
        },
      ],
      items: [
        {
          first: 'If this is your applicable facility type',
          last: 'Enter this abbreviated facility type on your application.',
        },
      ],
      fields1: [
        {
          key: 'left',
          label: 'CCLD Facility Type by Category',
        },
        {
          key: 'right',
          label: 'DOJ Abbreviated CCLD Facility Type',
        },
      ],
      items1: [
        {
          left: 'Home Care Aide',
          right: 'Home Care Aide',
        },
        {
          left: 'Home Care Organization',
          right: 'Home Care Organization',
        },
        {
          left: 'Adult Day Care Facility\nAdult Day Support Center\nAdult Residential Facility\nSocial Rehabilitation Facility',
          right: 'Adult Day/Resident/Rehab',
        },
        {
          left: 'Child Care Center\nInfant Center\nMildly Ill Center\nSchool Age Child Care Center',
          right: 'Day Care Center more/6 Child',
        },
        {
          left: 'Family Child Care Home',
          right: 'Family Day Care',
        },
        {
          left: 'Foster Family Agency\nFoster Family/Adoptions Agency\nFoster Family Agency Sub Office',
          right: 'Foster Family/Adopt Employment',
        },
        {
          left: 'Foster Family Agency - Certified Home\nFoster Family Home',
          right: 'Foster Family Home',
        },
        {
          left: 'Group Home (6 or less children)',
          right: 'Group Home 6/child less',
        },
        {
          left: 'Group Home (7 or more)\nCommunity Treatment Facility',
          right: 'Group Home more/6 child',
        },
        {
          left: 'Residential Care Facility for the Chronically Ill\nResidential Care Facilities for the Elderly',
          right: 'Residential Care Facility Elderly',
        },
        {
          left: 'Small Family Home\nTransitional Housing Placement Program',
          right: 'Residential Child Care 6/less',
        },
      ],

      isNotSaved: false,

      forms: this.$route.name,

      formUpdateChanges: [],

      required,
    }
  },
  watch: {
    // eslint-disable-next-line func-names
    'formUpdate.data': function () {
      Object.entries(this.formUpdate.data).forEach(([key, val]) => {
        if (JSON.stringify(val) !== JSON.stringify(this.formCopy[key]) && (Number(val) !== Number(this.formCopy[key]) || String(val) !== String(this.formCopy[key]))) {
          this.formUpdateChanges.push(this.$refs[key].$attrs.title)
        }
      })
    },
  },
  created() {
    this.sock.onopen = () => {
      // console.log('Connection established.')
    }

    this.sock.onmessage = event => {
      const data = JSON.parse(event.data)
      if (data.id === this.sockId) {
        this.getSockMsg(data.msg)
      }
    }

    this.sock.onclose = () => {
      // console.warn('Connection closed.')
    }
  },
  methods: {
    getSockMsg(sockMsg) {
      if (sockMsg === true) {
        // Do something
      }
    },
    sendSockMsg(sockMsg) {
      this.sock.send(JSON.stringify({ id: this.sockId, msg: sockMsg }))
    },
    requestFormUpdate() {
      const self = this

      store
        .dispatch('app-hr/requestFormUpdate', this.formCopy)
        .then(response => {
          // eslint-disable-next-line
          const jsonObjectLiterals = response.data.response.data

          self.formUpdateStatus = 1

          self.sendSockMsg(true)

          // console.log(jsonObjectLiterals.data)
        })
        .catch(error => {
          console.error(error)
        })
    },
    denyFormUpdate() {
      const self = this

      store
        .dispatch('app-hr/denyFormUpdate', this.formCopy)
        .then(response => {
          // eslint-disable-next-line
          const jsonObjectLiterals = response.data.response.data

          self.formUpdateStatus = 0

          if (jsonObjectLiterals.data.id) {
            self.form = jsonObjectLiterals.data
          }

          self.sendSockMsg(true)

          // console.log(jsonObjectLiterals.data)
        })
        .catch(error => {
          console.error(error)
        })
    },
    approveFormUpdate() {
      const self = this

      store
        .dispatch('app-hr/approveFormUpdate', this.formCopy)
        .then(response => {
          // eslint-disable-next-line
          const jsonObjectLiterals = response.data.response.data

          self.formUpdateStatus = 0

          self.sendSockMsg(true)

          // console.log(jsonObjectLiterals.data)
        })
        .catch(error => {
          console.error(error)
        })
    },
    dismissFormUpdate() {
      const self = this

      store
        .dispatch('app-hr/dismissFormUpdate', this.formCopy)
        .then(response => {
          // eslint-disable-next-line
          const jsonObjectLiterals = response.data.response.data

          self.formUpdateStatus = 0

          // console.log(jsonObjectLiterals.data)
        })
        .catch(error => {
          console.error(error)
        })
    },
    validationForm() {
      const self = this

      this.$refs.simpleRules.validate().then(success => {
        /* this.isNotSaved = true

        store
          .dispatch(this.action === 'add' ? 'app-hr/addForm10' : 'app-hr/updateForm10', this.form)
          .then(response => {
            // eslint-disable-next-line
            const jsonObjectLiterals = response.data.response.data

            this.form.id = jsonObjectLiterals.id

            // console.log(jsonObjectLiterals)

            Swal.fire({
              icon: 'success',
              title: 'Saved!',
              text: 'Saved successfully.',
              showConfirmButton: false,
              timer: 1500,
            })
          })
          .catch(error => {
            console.error(error)

            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: 'Something went wrong!',
              showConfirmButton: false,
              timer: 1500,
            })
          })
          .finally(() => {
            self.isNotSaved = false
          }) */
        if (success) {
          let msg = 'Do you want to save the changes?'
          if (this.action === 'add') msg = 'Do you want to add these data?'
          Swal.fire({
            icon: 'warning',
            title: 'Are you sure?',
            text: msg,
            confirmButtonText: 'Proceed',
            showCancelButton: true,
            reverseButtons: true,
            customClass: {
              confirmButton: 'btn btn-secondary',
              cancelButton: 'btn btn-outline-dark mr-1',
            },
            buttonsStyling: false,
            returnFocus: false,
          }).then(result => {
            if (result.isConfirmed) {
              this.isNotSaved = true

              store
                .dispatch(this.action === 'add' ? 'app-hr/addForm10' : 'app-hr/updateForm10', this.form)
                .then(response => {
                  // eslint-disable-next-line
                  const jsonObjectLiterals = response.data.response.data

                  this.form.id = jsonObjectLiterals.id

                  // console.log(jsonObjectLiterals)

                  if (this.userData.role === 'agent' && this.action === 'edit' && jsonObjectLiterals.count > 0) {
                    self.requestFormUpdate()
                  }

                  Swal.fire({
                    icon: 'success',
                    title: 'Saved!',
                    text: 'Saved successfully.',
                    showConfirmButton: false,
                    timer: 1500,
                  })
                })
                .catch(error => {
                  console.error(error)

                  Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Something went wrong!',
                    showConfirmButton: false,
                    timer: 1500,
                  })
                })
                .finally(() => {
                  self.isNotSaved = false
                })
            }
          })

          // eslint-disable-next-line
          // alert('form submitted!')
        } else {
          Swal.fire({
            icon: 'error',
            // title: 'Are you sure?',
            // eslint-disable-next-line
            text: 'Please fill-up all of the required fields.',
            confirmButtonText: 'OK',
            showCancelButton: false,
            reverseButtons: true,
            customClass: {
              confirmButton: 'btn btn-secondary',
              cancelButton: 'btn btn-outline-dark mr-1',
            },
            buttonsStyling: false,
            returnFocus: false,
          })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>

<style>
.ws-pre-line {
  white-space: pre-line;
}
</style>

<style lang="scss" scoped>
.hr-forms-select {
  width: 100%;
  min-width: 182px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 135px;
  }
}

@media (max-width: 1199.98px) {
  .hr-forms-select {
    min-width: 100%;

    ::v-deep .vs__selected {
      width: 112px;
    }
  }
}

@media (max-width: 991.98px) {
  .hr-forms-select {
    min-width: 100%;

    ::v-deep .vs__selected {
      width: 598px;
    }
  }
}

@media (max-width: 767.98px) {
  .hr-forms-select {
    min-width: 100%;

    ::v-deep .vs__selected {
      width: 406px;
    }
  }
}

@media (max-width: 575.98px) {
  .hr-forms-select {
    min-width: 100%;

    ::v-deep .vs__selected {
      width: 352px;
    }
  }
}
</style>
